import React, { useEffect } from "react";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import img1 from "../assets/img/product/product1.png";
import { Link, useNavigate } from "react-router-dom";

// import UseCartStore from "../store/usecartStore";
import useUpdateCartStore from "../store/cart";
import { toast } from "react-toastify";

const Cart = () => {
  const { cartItems, deleteItem, handleIncrement, handleDecrement, resetCart } =
    useUpdateCartStore();

  const navigate = useNavigate();

  const subtotal = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Calculate grand total (considering any additional fees, taxes, etc., if applicable)
  const grandTotal = subtotal; // Modify this if there are additional costs

  const handleCheckOut = () => {
    navigate("/checkout", { state: { products: cartItems } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(cartItems);
  }, []);

  const baseUrl = "https://api.medmall.ng";

  const cartItemRows = cartItems.map((item) => {
    let itemImageUrl = item.image.split("https://api.medmall.ng");
    let imageUrl;
    if (itemImageUrl.length == 2) {
      imageUrl = `${baseUrl}${itemImageUrl[1]}`;
    } else {
      imageUrl = `${baseUrl}${item.image}`;
    }

    return (
      <tr key={item.id} className="cart__table--body__items">
        <td className="cart__table--body__list">
          <div className="cart__product d-flex align-items-center">
            <div className="cart__thumbnail">
              <div className="d-grid">
                <img
                  className="border-radius-5 mb-5"
                  src={imageUrl}
                  alt={item.name}
                />

                <span
                  onClick={() => deleteItem(item?.id)}
                  style={{
                    color: "red",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    style={{ color: "red", fill: "red" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20px"
                    height="20px"
                  >
                    <path d="M 10 2 L 9 3 L 4 3 L 4 5 L 5 5 L 5 20 C 5 20.522222 5.1913289 21.05461 5.5683594 21.431641 C 5.9453899 21.808671 6.4777778 22 7 22 L 17 22 C 17.522222 22 18.05461 21.808671 18.431641 21.431641 C 18.808671 21.05461 19 20.522222 19 20 L 19 5 L 20 5 L 20 3 L 15 3 L 14 2 L 10 2 z M 7 5 L 17 5 L 17 20 L 7 20 L 7 5 z M 9 7 L 9 18 L 11 18 L 11 7 L 9 7 z M 13 7 L 13 18 L 15 18 L 15 7 L 13 7 z" />
                  </svg>
                  Remove
                </span>
              </div>
            </div>
            <div className="cart__content">
              <h4 className="cart__content--title">{item.name}</h4>
            </div>
          </div>
        </td>
        <td className="cart__table--body__list">
          <span className="cart__price">{item.price}</span>
        </td>
        <td className="cart__table--body__list">
          <div className="quantity__box">
            <button
              type="button"
              className="quantity__value quickview__value--quantity decrease"
              aria-label="quantity value"
              value="Decrease Value"
              onClick={() => handleDecrement(item?.id)}
            >
              -
            </button>
            <label>
              <input
                type="number"
                className="quantity__number quickview__value--number"
                data-counter
                readOnly
                value={item.quantity}
              />
            </label>
            <button
              type="button"
              className="quantity__value quickview__value--quantity increase"
              aria-label="quantity value"
              value="Increase Value"
              onClick={() => {
                if (item.quantity <= item.qty) {
                  handleIncrement(item?.id);
                }
              }}
              disabled={item.quantity >= item.qty}
            >
              +
            </button>
          </div>
        </td>
        <td className="cart__table--body__list">
          <span className="cart__price end">₦{item.price * item.quantity}</span>
        </td>

        {/* ...rest of your table cells */}
      </tr>
    );
  });
  // const handleIncrement=(id)=>{
  //   if(item.quantity > item.qty){

  //   }
  // }
  return (
    <>
      <main class="main__content_wrapper">
        {/* <!-- cart section start --> */}
        <section className="cart__section section--padding">
          <div className="container-fluid">
            <div className="cart__section--inner">
              <div>
                <h2 className="cart__title mb-40">Shopping Cart</h2>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="cart__table">
                      <table className="cart__table--inner">
                        <thead className="cart__table--header">
                          <tr className="cart__table--header__items">
                            <th className="cart__table--header__list">
                              Product
                            </th>
                            <th className="cart__table--header__list">Price</th>
                            <th className="cart__table--header__list">
                              Quantity
                            </th>
                            <th className="cart__table--header__list">Total</th>
                          </tr>
                        </thead>
                        <tbody className="cart__table--body">
                          {cartItemRows}
                        </tbody>
                      </table>
                      <div className="continue__shopping d-flex justify-content-between">
                        <Link className="continue__shopping--link" to="/">
                          Continue shopping
                        </Link>
                        <button
                          className="continue__shopping--clear"
                          type="button"
                          onClick={() => resetCart()}
                        >
                          Clear Cart
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="cart__summary border-radius-10">
                      <div className="cart__summary--total mb-20">
                        <table className="cart__summary--total__table">
                          <tbody>
                            <tr className="cart__summary--total__list">
                              <td className="cart__summary--total__title text-left">
                                TOTAL
                              </td>
                              <td className="cart__summary--amount text-right">
                                ₦{subtotal}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="cart__summary--footer">
                        <p className="cart__summary--footer__desc">
                          Shipping & taxes calculated at checkout
                        </p>
                        <ul className="d-flex justify-content-between">
                          <li>
                            <Link
                              to="/"
                              className="cart__summary--footer__btn primary__btn cart"
                            >
                              Update Cart
                            </Link>
                          </li>
                          <li>
                            <div>
                              <button
                                className="cart__summary--footer__btn primary__btn checkout"
                                type="button"
                                disabled={cartItems.length === 0}
                                onClick={handleCheckOut}
                              >
                                Check Out
                              </button>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- cart section end --> */}
      </main>
    </>
  );
};

export default Cart;
