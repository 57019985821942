import React, { useState } from "react";

import logo from "../assets/img/logo/medmall.svg";
import { AiFillFacebook } from "react-icons/ai";
import { BsTwitter } from "react-icons/bs";
import { BsWhatsapp } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

import { Link } from "react-router-dom";

const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [isOpenCompany, setIsOpencompany] = useState(false);
  const [isOpenLinks, setIsOpenLinks] = useState(false);
  const [isOpenAccount, setIsOpenAccount] = useState(false);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };
  const toggleContentCompany = () => {
    setIsOpencompany(!isOpenCompany);
  };
  const toggleContentLinks = () => {
    setIsOpenLinks(!isOpenLinks);
  };
  const toggleContentAccount = () => {
    setIsOpenAccount(!isOpenAccount);
  };
  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <footer
        className="footer__section color-scheme-2"
        style={{ backgroundColor: "#000000" }}
      >
        <div className="container-fluid">
          <div className="main__footer">
            <div className="row">
              <div className="col-md-4 footer__widget--width">
                <h2 className="footer__widget--title text-ofwhite h3">
                  About Us
                </h2>
                <div className="footer__widget--inner">
                  <p
                    className="footer__widget--desc text-ofwhite mb-20 footer-wid"
                    style={{ maxWidth: "400px" }}
                  >
                    Medmall is a one stop shop for medicines, beauty and
                    cosmetics, groceries, bread and other confectioneries
                  </p>
                  <div className="foot">
                    <Link className="main__logo--link" to="/">
                      <img
                        className="main__logo--img"
                        src={logo}
                        alt="logo-img"
                        width={"200px"}
                        height={"200px"}
                      />
                    </Link>
                    <p className="time" style={{ color: "#fff" }}>
                      Mon - Sundays, 8:00AM to 9:00PM
                    </p>
                  </div>
                </div>
                {/* <div className="">
                  <p className="footer__widget--desc text-ofwhite">
                    Medmall is a one stop shop for medicines, beauty and
                    cosmetics, groceries, bread and other confectioneries
                  </p>
                </div> */}
              </div>

              <div className=" col-md-3 footer__widget">
                <h2 className="footer__widget--title text-ofwhite h3">
                  Quick Links
                </h2>

                <ul className="footer__widget--menu footer__widget--inner">
                  <li className="footer__widget--menu__list">
                    <Link
                      className="footer__widget--menu__text"
                      to="privacy/policy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li className="footer__widget--menu__list">
                    <Link
                      className="footer__widget--menu__text"
                      to="terms-and-condition"
                    >
                      Our Terms & Conditions
                    </Link>
                  </li>
                  <li className="footer__widget--menu__list">
                    <a
                      className="footer__widget--menu__text"
                      href="https://docs.google.com/forms/d/e/1FAIpQLSeq67RzCs0Nbmfm9ou7wObtbV7vymkMFtOw_7RUENBHg_o42Q/viewform?usp=sf_link"
                    >
                      Talk to a Pharmacist
                    </a>
                  </li>
                </ul>
              </div>
              <div className=" col-md-2 footer__widget">
                <h2 className="footer__widget--title text-ofwhite h3">
                  Company
                </h2>

                <ul className="footer__widget--menu footer__widget--inner">
                  <li className="footer__widget--menu__list">
                    <Link className="footer__widget--menu__text" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li className="footer__widget--menu__list">
                    <Link className="footer__widget--menu__text" to="/contact">
                      Contact Us
                    </Link>
                  </li>
                  <li className="footer__widget--menu__list">
                    <Link
                      className="footer__widget--menu__text"
                      to="/shop/bakery-confectionaries/2"
                    >
                      Shop
                    </Link>
                  </li>
                </ul>
              </div>
              <div className=" col-md-2 footer__widget">
                <h2 className="footer__widget--title text-ofwhite h3">
                  My Account
                </h2>

                <ul className="footer__widget--menu footer__widget--inner">
                  <li className="footer__widget--menu__list">
                    <Link className="footer__widget--menu__text" to="/account">
                      Profile
                    </Link>
                  </li>
                  <li
                    className="footer__widget--menu__list"
                    onClick={handleModal}
                    style={{ cursor: "pointer" }}
                  >
                    <p className="footer__widget--menu__text">Track Order</p>
                  </li>
                  <li className="footer__widget--menu__list">
                    <Link className="footer__widget--menu__text" to="/cart">
                      Cart
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer__bottom d-flex justify-content-between align-items-center">
            <p className="copyright__content text-ofwhite m-0">
              2024 Medmall pharmacy and store. All rights reserved.
            </p>

            <div className="footer__widget--inner footer__social--style3">
              <ul
                className="social__shear"
                style={{ position: "relative", top: "10px" }}
              >
                <li className="social__shear--list">
                  <a
                    className="social__shear--list__icon"
                    target="_blank"
                    href="https://x.com/Medmallp"
                  >
                    <BsTwitter color="white" fontSize="22px" />
                  </a>
                </li>
                <li className="social__shear--list">
                  <a
                    className="social__shear--list__icon"
                    target="_blank"
                    href="http://wa.me/message/AXDEBWAGASAYE1"
                  >
                    <BsWhatsapp color="white" fontSize="22px" />
                  </a>
                </li>
                <li className="social__shear--list">
                  <a
                    className="social__shear--list__icon"
                    target="_blank"
                    href="https://www.facebook.com/medmallpharm"
                  >
                    <AiFillFacebook color="white" fontSize="22px" />
                  </a>
                </li>
                <li className="social__shear--list">
                  <a
                    className="social__shear--list__icon"
                    target="_blank"
                    href="https://www.linkedin.com/company/medmallpharmacy/"
                  >
                    <BsLinkedin color="white" fontSize="22px" />
                  </a>
                </li>
                <li className="social__shear--list">
                  <a
                    className="social__shear--list__icon"
                    target="_blank"
                    href="https://instagram.com/medmall_pharmacy?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr"
                  >
                    <BsInstagram color="white" fontSize="22px" />
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer__payment text-right">
              <p className="copyright__content text-ofwhite m-0">
                By Vaticore Technologies
              </p>
            </div>
          </div>
        </div>
      </footer>
      {modal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "rgba(21, 14, 40, 0.93)",
            transition: "all easeInOut 500ms",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999",
          }}
        >
          <div
            style={{
              border: "1px solid #2b1770",
              padding: "2rem",
              margin: "auto",
              borderRadius: "4px",
            }}
          >
            <p className="text-white">
              Thank you for your order, please check your email for order
              details. Thank you!
            </p>
            <button
              onClick={() => setModal(false)}
              style={{
                margin: "2rem auto 0",
                fontSize: "16px",
                backgroundColor: "#2b1770",
                borderRadius: "0.25rem",
                border: "none",
                color: "#fff",
                padding: "10px",
                width: "100%",
                display: "block",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
