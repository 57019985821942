import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";

import icon1 from "../assets/img/icon/clipboard-list.png";
import icon2 from "../assets/img/icon/star.png";
import icon3 from "../assets/img/icon/chat-alt.png";
import logo from "../assets/img/logo/Medmall.png";
const DashboardAccount = () => {
  return (
    <>
      <section>
        <div className="my__account--section__inner d-flex pl-2">
          <div className="account__left--sidebar">
            <div className="" style={{ padding: "0px" }}>
              <img src={logo} alt="" />
            </div>
            <h2
              className="account__content--title h3 mb-20"
              style={{ paddingLeft: "10px", paddingTop: "50px" }}
            >
              My Dashboard
            </h2>
            <ul className="account__menu" style={{ paddingLeft: "10px" }}>
              <Link
                to="/account/order-history"
                className="account__menu--list active"
              >
                <span>
                  <img src={icon1} alt="" />
                </span>
                Orders
              </Link>
              <Link className="account__menu--list" to="/account/testimonial">
                <span>
                  <img src={icon2} alt="" />
                </span>
                Testimonials
              </Link>
              <Link
                to="/account/product-rating"
                className="account__menu--list"
              >
                {" "}
                <span>
                  <img src={icon3} alt="" />
                </span>
                Product Rating
              </Link>
            </ul>
          </div>

          <div className="account__content account">
            <Outlet /> {/* Outlet to render nested routes */}
          </div>
        </div>
      </section>
      {/* <!-- my account section end --> */}
    </>
  );
};

export default DashboardAccount;
