import img1 from "../src/assets/img/product/product4.svg";
import img2 from "../src/assets/img/product/product5.svg";
import img3 from "../src/assets/img/product/product6.svg";
import img4 from "../src/assets/img/product/product7.svg";
import img5 from "../src/assets/img/product/product8.svg";
import img6 from "../src/assets/img/product/product6.svg";
import img7 from "../src/assets/img/product/product7.svg";
import img8 from "../src/assets/img/product/product4.svg";
import img9 from "../src/assets/img/product/product5.svg";
import img10 from "../src/assets/img/product/product8.svg";
import img11 from "../src/assets/img/product/product5.svg";
import img12 from "../src/assets/img/product/product4.svg";
import img13 from "../src/assets/img/product/product6.svg";
import img14 from "../src/assets/img/product/product8.svg";
import img15 from "../src/assets/img/product/product7.svg";
import img16 from "../src/assets/img/product/product4.svg";

export const PRODUCTSBAKERY = [
  {
    id: 1,
    img: img1,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },

  {
    id: 2,
    img: img2,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 3,
    img: img3,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 4,
    img: img4,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },

  {
    id: 5,

    img: img5,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 6,
    img: img6,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 7,

    img: img7,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },

  {
    id: 8,

    img: img8,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 9,
    img: img9,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 10,
    img: img10,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },

  {
    id: 11,
    img: img11,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 12,
    img: img12,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 13,
    img: img13,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 14,
    img: img14,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 15,
    img: img15,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",
    current_price: 110,
    old_price: 78,
    color: "blue",
  },
  {
    id: 16,
    img: img16,
    sale: "sale",
    title: "Insert Product Title",
    product_title: "Insert Product Name",

    current_price: 110,
    old_price: 78,
    color: "blue",
  },
];






