import { create } from "zustand";
import { persist } from "zustand/middleware";
const useStore = (set) => ({
  cartItems: [],
  addToCart: (product) =>
    set((state) => {
      const existingIndex = state.cartItems.findIndex(
        (item) => item.id === product.id
      );

      if (existingIndex !== -1) {
        // Product already exists, update quantity
        const updatedCartItems = [...state.cartItems];
        // updatedCartItems[existingIndex].quantity += product.quantity;
      
        return { cartItems: updatedCartItems };
      } else {
        // Product doesn't exist, add a new entry
        return { cartItems: [...state.cartItems, product] };
      }
    }),
  deleteItem: (itemId) =>
    set((state) => {
      const updatedCartItems = state.cartItems.filter(
        (item) => item.id !== itemId
      );
      return { cartItems: updatedCartItems };
    }),
  handleDecrement: (itemId) =>
    set((state) => {
      const updatedCartItems = [...state.cartItems];
      const itemIndex = updatedCartItems.findIndex(
        (item) => item.id === itemId
      );

      if (itemIndex !== -1) {
        const updatedItem = {
          ...updatedCartItems[itemIndex],
          quantity: Math.max(updatedCartItems[itemIndex].quantity - 1, 1),
        };
        updatedCartItems[itemIndex] = updatedItem;
        return { cartItems: updatedCartItems };
      }

      return state;
    }),
  handleIncrement: (itemId) =>
    set((state) => {
      const updatedCartItems = [...state.cartItems];
      const itemIndex = updatedCartItems.findIndex(
        (item) => item.id === itemId
      );

      if (itemIndex !== -1) {
        const updatedItem = {
          ...updatedCartItems[itemIndex],
          quantity: updatedCartItems[itemIndex].quantity + 1,
        };
        updatedCartItems[itemIndex] = updatedItem;
        return { cartItems: updatedCartItems };
      }

      return state;
    }),
  resetCart: () =>
    set({
      cartItems: [],
    }),
});

const useUpdateCartStore = create(
  persist(useStore, {
    name: "CartStore",
  })
);

export default useUpdateCartStore;
