import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const Hero = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Set to true for automatic sliding
    autoplaySpeed: 2000, // Set the interval (in milliseconds)
  };

  return (
    <div>
      <section className="hero__slider--section">
        <div className="hero__slider--inner hero__slider--activation swiper">
          <div className="hero__slider--wrapper swiper-wrapper">
            <Slider {...settings}>
              <Link
                to="https://docs.google.com/forms/d/e/1FAIpQLSeq67RzCs0Nbmfm9ou7wObtbV7vymkMFtOw_7RUENBHg_o42Q/viewform?usp=sf_link"
                className=""
              >
                <div className="hero__slider--items home1__slider--bg">
                  <div className="container-fluid"></div>
                </div>
              </Link>
              <Link to="/shop/pharmacy-store/1" className="">
                <div className="hero__slider--items home1__slider--bg two">
                  <div className="container-fluid"></div>
                </div>
              </Link>
              <Link to="/shop/pharmacy-store/1" className="">
                <div className="hero__slider--items home1__slider--bg three">
                  <div className="container-fluid"></div>
                </div>
              </Link>
              <Link to="/shop/pharmacy-store/1" className="">
                <div className="hero__slider--items home1__slider--bg four">
                  <div className="container-fluid"></div>
                </div>
              </Link>
              <Link to="/shop/bakery-confectionaries/2" className="">
                <div className="hero__slider--items home1__slider--bg five">
                  <div className="container-fluid"></div>
                </div>
              </Link>
            </Slider>
          </div>
          {/* <div className="swiper__nav--btn swiper-button-next"></div>
                <div className="swiper__nav--btn swiper-button-prev"></div> */}
        </div>
      </section>
    </div>
  );
};

export default Hero;
