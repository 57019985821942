import React, { useState } from "react";
import img1 from "../assets/img/icon/image.png";
import { Link } from "react-router-dom";
import icon from "../assets/img/icon/cog.png";

const DashboardOrderHistory = () => {
  const [status, setStatus] = useState("orderHistory");

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
  };

  let content;
  if (status === "orderHistory") {
    content = (
      <div className="account__table--area">
        <table className="account__table">
          <thead className="account__table--header">
            <tr
              className="account__table--header__child"
              style={{ border: "none" }}
            >
              <th className="account__table--header__child--items">Order ID</th>

              <th className="account__table--header__child--items">Items</th>
              <th className="account__table--header__child--items">
                Date/Time
              </th>
              <th className="account__table--header__child--items">Quantity</th>
              <th className="account__table--header__child--items">
                Payment Status
              </th>
              <th className="account__table--header__child--items">Total</th>
              <th className="account__table--header__child--items">Status</th>
            </tr>
          </thead>
          <tbody className="account__table--body mobile__none">
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <div className="button-container">
                  <button>Accept</button>
                  <button style={{ backgroundColor: "#FF4332" }}>Reject</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else if (status === "pending") {
    content = (
      <div className="account__table--area">
        <table className="account__table">
          <thead className="account__table--header">
            <tr
              className="account__table--header__child"
              style={{ border: "none" }}
            >
              <th className="account__table--header__child--items">Order ID</th>

              <th className="account__table--header__child--items">Items</th>
              <th className="account__table--header__child--items">
                Date/Time
              </th>
              <th className="account__table--header__child--items">Quantity</th>
              <th className="account__table--header__child--items">
                Payment Status
              </th>
              <th className="account__table--header__child--items">Total</th>
              <th className="account__table--header__child--items">Progress</th>
            </tr>
          </thead>
          <tbody className="account__table--body mobile__none">
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                <button className="pending-btn">
                  <img src={icon} alt="" />
                  <span className="span">Set Progress</span>
                </button>
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                {" "}
                <button className="pending-btn">
                  <img src={icon} alt="" />
                  <span className="span">Set Progress</span>
                </button>{" "}
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
              <td className="account__table--body__child--items">
                {" "}
                <button className="pending-btn">
                  <img src={icon} alt="" />
                  <span className="span">Set Progress</span>
                </button>{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else if (status === "completed") {
    content = (
      <div className="account__table--area">
        <table className="account__table">
          <thead className="account__table--header">
            <tr
              className="account__table--header__child"
              style={{ border: "none" }}
            >
              <th className="account__table--header__child--items">Order ID</th>

              <th className="account__table--header__child--items">Items</th>
              <th className="account__table--header__child--items">
                Date/Time
              </th>
              <th className="account__table--header__child--items">Quantity</th>
              <th className="account__table--header__child--items">
                Payment Status
              </th>
              <th className="account__table--header__child--items">Total</th>
            </tr>
          </thead>
          <tbody className="account__table--body mobile__none">
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
            </tr>
            <tr className="account__table--body__child">
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                #000001
              </td>
              <td
                className="account__table--body__child--items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <img src={img1} alt="" />
                Cup cake
              </td>
              <td className="account__table--body__child--items">
                12-Dec-23 / 12:30 PM
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "40px" }}
              >
                3pcs
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "60px" }}
              >
                paid
              </td>
              <td
                className="account__table--body__child--items"
                style={{ paddingLeft: "20px" }}
              >
                # 6,000
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <>
      <div className="account__wrapper">
        <div className="account__content">
          <div className="" style={{ display: "flex" }}>
            <span
              className="account__content--title h3 mb-20"
              onClick={() => handleStatusChange("orderHistory")}
              style={{
                cursor: "pointer",
                fontWeight: status === "orderHistory" ? "bold" : "normal",
              }}
            >
              Orders History
            </span>
            <div className="pending-status">
              <span
                onClick={() => handleStatusChange("pending")}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  fontWeight: status === "pending" ? "bold" : "normal",
                }}
              >
                Pending
              </span>
              <span
                onClick={() => handleStatusChange("completed")}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  fontWeight: status === "completed" ? "bold" : "normal",
                }}
              >
                Completed
              </span>
            </div>
          </div>
          {content}
        </div>
      </div>
    </>
  );
};

export default DashboardOrderHistory;
