import React, { useEffect, useState } from "react";
import product1 from "../assets/img/product/small-product10.png";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import UseCartStore from "../store/usecartStore";
import useAuthStore from "../store/store";
import { Axios } from "../config";
import requests from "../lib/requests";
import Pagination from "../components/paginator";
import useUpdateCartStore from "../store/cart";

const Shop = () => {
  const [sortedProducts, setSortedProducts] = useState([]);
  const [sortBy, setSortBy] = useState("1");
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [isSubMenuVisible1, setIsSubMenuVisible1] = useState(false);
  const [isSubMenuVisible2, setIsSubMenuVisible2] = useState(false);
  const [isSubMenuVisible3, setIsSubMenuVisible3] = useState(false);
  const { slug, id } = useParams();

  const [productToAddAfterLogin, setProductToAddAfterLogin] = useState(null);
  const [products, setProducts] = useState([]);
  const [prod, setProd] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [visibleSubCategories, setVisibleSubCategories] = useState({});
  const { mainCate } = useAuthStore();

  useEffect(() => {
    // Function to fetch categories
    const fetchCategories = async () => {
      try {
        const response = await Axios.get(requests.subCategories);
        setCategories(response.data); // Assuming data is an array of categories
      } catch (error) {
        console.error("Failed to fetch categories:", error);
      }
    };

    // Function to fetch subcategories
    const fetchSubCategories = async () => {
      try {
        const response = await Axios.get(requests.categories);
        setSubCategories(response.data);
      } catch (error) {
        console.error("Failed to fetch subcategories:", error);
      }
    };

    fetchCategories();
    fetchSubCategories();
  }, []);

  const toggleSubCategoryVisibility = (categoryId) => {
    setVisibleSubCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  useEffect(() => {
    GetProducts();
    const productsWithCategoryIdOne = Array.isArray(products)
      ? products.filter((product) => product.maincat == id)
      : [];
    setProd(productsWithCategoryIdOne);
  }, [id]);

  const GetProducts = async () => {
    try {
      const res = await Axios.get(requests.products);
      setProducts(res.data); // Update products state with fetched data
    } catch (error) {
      console.log("Error fetching products:", error);
    }
  };

  // const addToCart = UseCartStore((state) => state.addToCart);
  // const cartItems = UseCartStore((state) => state.cartItems);

  const { addToCart } = useUpdateCartStore();

  const { token } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [openKey, setOpenKey] = useState();
  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 4;
  const endOffset = itemOffset + itemsPerPage;
  let currentData = Array.isArray(
    products.filter((product) => product.maincat == id)
  )
    ? products
        .filter((product) => product.maincat == id)
        ?.slice(itemOffset, endOffset)
    : [];

  // const toggleSubMenu = () => {
  //   setIsSubMenuVisible(!isSubMenuVisible);
  //   console.log("Submenu visibility toggled!");
  // };
  const toggleSubMenu1 = (id) => {
    setIsSubMenuVisible1(!isSubMenuVisible1);
  };

  const handleToggle = (key) => {
    setOpenKey(openKey !== key ? key : null);
  };
  const toggleSubMenu2 = () => {
    setIsSubMenuVisible2(!isSubMenuVisible2);
  };
  const toggleSubMenu3 = () => {
    setIsSubMenuVisible3(!isSubMenuVisible3);
  };

  const handleCategoryProducts = (cat) => {
    console.log(cat);

    // Use strict equality (===) and the functional form of setProducts
    // let prods = products.filter(product => product.subcat === cat);
    // setProducts((prevProducts) => [...prods]);

    // You might not need to use a separate variable for 'prods'
    setProducts((prevProducts) => [
      ...products.filter((product) => product.subcat === cat),
    ]);
  };

  const pageCount = Math.ceil(
    Array.isArray(products.filter((product) => product.maincat == id))
      ? products.filter((product) => product.maincat == id)?.length /
          itemsPerPage
      : 0
  );

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      products.filter((product) => product.maincat == id)?.length;
    setItemOffset(newOffset);
  };

  const handleAddToCart = (product) => {
    // if (token) {
    //   const existingProduct = cartItems.find((item) => item.id === product.id);
    //   if (existingProduct) {
    //     toast.warning(`${product.name} is already in the cart`, {
    //       // Add your toast configurations here
    //     });
    //   } else {
    //     addToCart(product);
    //     toast.success(`${product.name} added to cart`, {
    //       // Add your toast configurations here
    //     });
    //   }
    // } else {
    //   // Store product to add after login in state
    //   navigate("/login"); // Redirect to login page
    // }
    const productWithQuantity = { ...product, quantity: 1 };

    addToCart(productWithQuantity);
    setProductToAddAfterLogin(product);
    toast.success(`${product.name} added to cart`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (token && productToAddAfterLogin) {
      addToCart(productToAddAfterLogin);
      setProductToAddAfterLogin(null); // Clear the pending product
      navigate(location.pathname); // Redirect back to the previous page
    }
  }, [token, productToAddAfterLogin, addToCart, location.pathname]);

  const sortProducts = (sortOrder) => {
    let sorted = [...products];
    if (sortOrder === "1") {
      // Price: Low to High
      sorted = sorted.sort((a, b) => a.price - b.price);
    } else if (sortOrder === "2") {
      // Price: High to Low
      sorted = sorted.sort((a, b) => b.price - a.price);
    }
    setSortedProducts(sorted);
  };

  // Handle change in sorting option
  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSortBy(selectedValue);
    sortProducts(selectedValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    // Filter products based on the search term
    const filteredProducts = products.filter((product) =>
      product.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setSortedProducts(filteredProducts);
  };

  // Use sortedProducts array for rendering products
  const productsToDisplay =
    sortedProducts.length > 0
      ? sortedProducts
      : currentData.filter((product) => product.maincat == id);

  useEffect(() => {
    window.scrollTo(0, 0);
    setItemOffset(0);
  }, []);

  return (
    <>
      <main className="main__content_wrapper">
        {/* <!-- Start shop section --> */}
        <section className="shop__section section--padding">
          <div className="container-fluid">
            <div className="shop__header bg__gray--color d-flex align-items-center justify-content-between mb-30">
              <div className="product__view--mode d-flex align-items-center">
                <div className="product__view--mode__list product__short--by align-items-center d-grid mb-5 d-lg-flex">
                  <label className="product__view--label">Sort By :</label>
                  <div className="select shop__header--select">
                    <select
                      className="product__view--select"
                      value={sortBy}
                      onChange={handleSortChange}
                    >
                      <option selected value="1">
                        Price: Low to High
                      </option>
                      <option value="2">Price: High to Low</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="product__view--mode__list product__view--search d-flex d-lg-block">
                <form className="product__view--search__form w-100" action="#">
                  <label>
                    <input
                      className="product__view--search__input borde-r-0"
                      placeholder="Search by"
                      type="text"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </label>
                  <button
                    className="product__view--search__btn"
                    aria-label="shop button"
                    type="submit"
                  >
                    <svg
                      className="product__view--search__btn--svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.51"
                      height="20.443"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M338.29 338.29L448 448"
                      />
                    </svg>
                  </button>
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-lg-4">
                <div className="shop__sidebar--widget widget__area d-none d-lg-block">
                  <div className="single__widget widget__bg">
                    <h2 className="widget__title h3">Categories</h2>
                    <ul className="widget__categories--menu">
                      {categories?.map(
                        (cat, i) =>
                          cat.maincat == id && (
                            <li
                              key={cat.id}
                              className="widget__categories--menu__list"
                            >
                              <label
                                className="widget__categories--menu__label d-flex align-items-center"
                                onClick={() => handleToggle(i)}
                              >
                                <span className="widget__categories--menu__text">
                                  {cat.name}
                                </span>
                                <svg
                                  className="widget__categories--menu__arrowdown--icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12.355"
                                  height="8.394"
                                >
                                  <path
                                    d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z"
                                    transform="translate(-6 -8.59)"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </label>
                              {openKey === i && (
                                <ul
                                  className="widget__categories--sub__menu"
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  {subCategories?.map(
                                    (subcate) =>
                                      subcate.cat == cat.id && (
                                        <li className="widget__categories--sub__menu--list">
                                          <span
                                            className="widget__categories--sub__menu--link d-flex align-items-center"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleCategoryProducts(
                                                subcate?.cat
                                              )
                                            }
                                          >
                                            <span className="widget__categories--sub__menu--text">
                                              {subcate.name}
                                            </span>
                                          </span>
                                        </li>
                                      )
                                  )}
                                </ul>
                              )}
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8">
                <div className="shop__product--wrapper">
                  <div className="tab_content">
                    <div id="product_grid" className="tab_pane active show">
                      <div className="product__section--inner product__grid--inner">
                        <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                          {productsToDisplay.map(
                            (product) =>
                              product.maincat == id && (
                                <div className="col mb-30">
                                  <div className="product__items">
                                    <div className="product__items--thumbnail">
                                      <Link
                                        className="product__items--link"
                                        to={`/productdetails/${product.slug}`}
                                        state={{
                                          id: product.id,
                                          image: `https://api.medmall.ng${product.image}`,
                                          price: product.price,
                                          name: product.name,
                                          description: product.description,
                                          additional_info:
                                            product.additional_info,
                                          // Add other necessary details here
                                        }}
                                      >
                                        <img
                                          className="product__items--img product__primary--img"
                                          src={`https://api.medmall.ng${product.image}`}
                                          alt={product.name}
                                          style={{
                                            width: "100%",
                                            height: "256px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Link>
                                    </div>
                                    <div
                                      className="product__items--content "
                                      style={{ margin: "0px 30px" }}
                                    >
                                      <span className="product__items--content__subtitle">
                                        {product.name}
                                      </span>
                                      <h3 className="product__items--content__title h4">
                                        {product.name}
                                      </h3>
                                      <div className="product__items--price">
                                        <span className="current__price">
                                          ₦{product.price}
                                        </span>
                                        {/* <span className="price__divided"></span> */}
                                        {/* <span className="old__price">
                                      {" "}
                                      ₦{product.price}{" "}
                                    </span> */}
                                      </div>
                                      <ul className="rating product__rating d-flex">
                                        <li className="rating__list">
                                          <span className="rating__list--icon">
                                            <svg
                                              className="rating__list--icon__svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14.105"
                                              height="14.732"
                                              viewBox="0 0 10.105 9.732"
                                            >
                                              <path
                                                data-name="star - Copy"
                                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                transform="translate(0 -0.018)"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </span>
                                        </li>
                                        <li className="rating__list">
                                          <span className="rating__list--icon">
                                            <svg
                                              className="rating__list--icon__svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14.105"
                                              height="14.732"
                                              viewBox="0 0 10.105 9.732"
                                            >
                                              <path
                                                data-name="star - Copy"
                                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                transform="translate(0 -0.018)"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </span>
                                        </li>
                                        <li className="rating__list">
                                          <span className="rating__list--icon">
                                            <svg
                                              className="rating__list--icon__svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14.105"
                                              height="14.732"
                                              viewBox="0 0 10.105 9.732"
                                            >
                                              <path
                                                data-name="star - Copy"
                                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                transform="translate(0 -0.018)"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </span>
                                        </li>
                                        <li className="rating__list">
                                          <span className="rating__list--icon">
                                            <svg
                                              className="rating__list--icon__svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14.105"
                                              height="14.732"
                                              viewBox="0 0 10.105 9.732"
                                            >
                                              <path
                                                data-name="star - Copy"
                                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                transform="translate(0 -0.018)"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </span>
                                        </li>
                                        <li className="rating__list">
                                          <span className="rating__list--icon">
                                            <svg
                                              className="rating__list--icon__svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14.105"
                                              height="14.732"
                                              viewBox="0 0 10.105 9.732"
                                            >
                                              <path
                                                data-name="star - Copy"
                                                d="M9.837,3.5,6.73,3.039,5.338.179a.335.335,0,0,0-.571,0L3.375,3.039.268,3.5a.3.3,0,0,0-.178.514L2.347,6.242,1.813,9.4a.314.314,0,0,0,.464.316L5.052,8.232,7.827,9.712A.314.314,0,0,0,8.292,9.4L7.758,6.242l2.257-2.231A.3.3,0,0,0,9.837,3.5Z"
                                                transform="translate(0 -0.018)"
                                                fill="currentColor"
                                              ></path>
                                            </svg>
                                          </span>
                                        </li>
                                      </ul>

                                      <ul className="product__items--action d-flex">
                                        <li className="product__items--action__list">
                                          <button
                                            className="product__items--action__btn add__to--cart"
                                            onClick={() =>
                                              handleAddToCart(product)
                                            }
                                          >
                                            <svg
                                              className="product__items--action__btn--svg"
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22.51"
                                              height="20.443"
                                              viewBox="0 0 14.706 13.534"
                                            >
                                              <g transform="translate(0 0)">
                                                <g>
                                                  <path
                                                    data-name="Path 16787"
                                                    d="M4.738,472.271h7.814a.434.434,0,0,0,.414-.328l1.723-6.316a.466.466,0,0,0-.071-.4.424.424,0,0,0-.344-.179H3.745L3.437,463.6a.435.435,0,0,0-.421-.353H.431a.451.451,0,0,0,0,.9h2.24c.054.257,1.474,6.946,1.555,7.33a1.36,1.36,0,0,0-.779,1.242,1.326,1.326,0,0,0,1.293,1.354h7.812a.452.452,0,0,0,0-.9H4.74a.451.451,0,0,1,0-.9Zm8.966-6.317-1.477,5.414H5.085l-1.149-5.414Z"
                                                    transform="translate(0 -463.248)"
                                                    fill="currentColor"
                                                  ></path>
                                                  <path
                                                    data-name="Path 16788"
                                                    d="M5.5,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,5.5,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,6.793,478.352Z"
                                                    transform="translate(-1.191 -466.622)"
                                                    fill="currentColor"
                                                  ></path>
                                                  <path
                                                    data-name="Path 16789"
                                                    d="M13.273,478.8a1.294,1.294,0,1,0,1.293-1.353A1.325,1.325,0,0,0,13.273,478.8Zm1.293-.451a.452.452,0,1,1-.431.451A.442.442,0,0,1,14.566,478.352Z"
                                                    transform="translate(-2.875 -466.622)"
                                                    fill="currentColor"
                                                  ></path>
                                                </g>
                                              </g>
                                            </svg>
                                            <span className="add__to--cart__text">
                                              {" "}
                                              + Add to cart
                                            </span>
                                          </button>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End shop section --> */}

        <div className="w-100 mt-6">
          <Pagination pageCount={pageCount} handlePageClick={handlePageClick} />
        </div>
      </main>
    </>
  );
};

export default Shop;
