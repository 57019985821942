import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../assets/img/other/testimonial-thumb1.png";
import image2 from "../assets/img/other/medmall.jpg";
import { Axios } from "../config";
import requests from "../lib/requests";
import { toast } from "react-toastify";

const About = () => {
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTeam = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(requests.teams);
      setTeamData(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch Team", {
        toastId: 1,
        duration: "300",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeam();
  }, []);

  return (
    <>
      <main className="main__content_wrapper">
        {/* <!-- Start breadcrumb section --> */}
        {/* <section className="breadcrumb__section breadcrumb__bg">
          <div className="container">
            
          </div>
        </section> */}
        {/* <!-- End breadcrumb section --> */}
        {/* <!-- Start about section --> */}
        <section className="about__section section--padding mb-95">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-lg-6">
                <img
                  className="img-fluid border-radius-5 display-block"
                  src={image2}
                  alt="about-thumb"
                  style={{ height: "80%", width: "100%" }}
                />
              </div>
              <div className="col-lg-6">
                <div className="about__content">
                  <span
                    className="about__content--subtitle text__secondary mb-40"
                    style={{ fontSize: "30px" }}
                  >
                   
                    Why Choose us
                  </span>
                  <h2 className="about__content--maintitle mb-25">
                    At Medmall, we understand that your health and wellness are
                    of utmost importance. That's why we strive to be more than
                    just a pharmacy - we're your trusted healthcare partner.
                    Here are a few reasons why you should choose us for all your
                    pharmaceutical and wellness needs:
                  </h2>
                  <ol
                    className="about__content--desc mb-20"
                    style={{ listStyleType: "-" }}
                  >
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Exceptional Service:</b> Our team is dedicated to
                      providing you with the highest level of care and service.
                      Whether you have questions about your medications or need
                      advice on managing a health condition, our knowledgeable
                      staff is here to help.
                    </li>
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Wide Range of Products:</b> From prescription
                      medications to over-the-counter remedies, vitamins, and
                      supplements, we offer a comprehensive selection of
                      products to meet your healthcare needs. Plus, we carry a
                      variety of wellness and beauty products to help you look
                      and feel your best.
                    </li>
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Convenience:</b> With multiple locations and convenient
                      hours, we make it easy for you to access the medications
                      and products you need. Our online ordering and
                      prescription refill services also ensure that you can get
                      what you need quickly and efficiently.
                    </li>
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Personalized Care:</b> We believe in treating each
                      customer as an individual with unique needs and concerns.
                      That's why we take the time to listen to you and provide
                      personalized recommendations to help you achieve your
                      health and wellness goals
                    </li>
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Community Engagement:</b> At Medmall, we're more than
                      just a pharmacy - we're a part of the community. We
                      actively support local initiatives and organizations to
                      promote health and wellness for everyone.
                    </li>
                    <li
                      className="about__content--desc mb-20"
                      style={{ listStyleType: "disc" }}
                    >
                      <b>Commitment to Quality:</b> Your safety and satisfaction
                      are our top priorities. That's why we adhere to the
                      highest standards of quality and safety in all aspects of
                      our business, from product selection to customer service.
                    </li>
                  </ol>
                  <p className="about__content--desc mb-20">
                    Thank you for considering Medmall Pharmacy and Stores for
                    your healthcare needs. We look forward to serving you and
                    helping you live a healthier, happier life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- end Start about section --> */}

        {/*  <!-- Start testimonial section --> */}
        <section class="testimonial__section section--padding">
          <div class="container">
            <div class="section__heading text-center mb-40">
              <h2 class="section__heading--maintitle">Our Team</h2>
            </div>
            {loading ? (
              "Team Loading..."
            ) : (
              <div className="row justify-content-md-center">
                {teamData.length === 0
                  ? "No Testimony Data"
                  : teamData.map((item) => (
                      <div className="col-lg-3" key={item.id}>
                        <div class="testimonial__items text-center">
                          <div class="testimonial__items--thumbnail">
                            <img
                              class="testimonial__items--thumbnail__img border-radius-50"
                              src={`https://api.medmall.ng${item.image}`}
                              alt="testimonial-img"
                            />
                          </div>
                          <div class="testimonial__items--content">
                            <h3 class="testimonial__items--title">
                              {item.full_name}
                            </h3>

                            <p class="testimonial__items--desc">
                              {item.post_held}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        </section>
        {/* <!-- End testimonial section --> */}
      </main>

      <button id="scroll__top">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="ionicon"
          viewBox="0 0 512 512"
        >
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="48"
            d="M112 244l144-144 144 144M256 120v292"
          />
        </svg>
      </button>
    </>
  );
};

export default About;
