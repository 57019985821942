import React from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { newsLetter } from "../schemas/validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Axios } from "../config";
import requests from "../lib/requests";
const Newsletter = () => {
  const initialValues = {
    email: "",
  };
  const onSubmit = async (values) => {
    try {
      // Perform actions related to form submission, e.g., sending data to the server
      // For example, using Axios or fetch to make a POST request
      // Replace this with your actual API call
     const res = await Axios.post(requests.newsLetter, values);

      // Display success message
      toast.success(res?.data.message);
    } catch (error) {
      // Handle error, show error message, etc.
      toast.error(error?.response.data.message[0]);
    }
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: newsLetter,
    onSubmit,
  });
  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <>
      <div
        className="brand__logo--section  section--padding color-scheme-2"
        style={{ backgroundColor: "#F9F9F9", height: "400px" }}
      >
        <div className="container-fluid">
          <div className="contain">
            <form action="" onSubmit={handleSubmit}>
              <h1> Newsletter</h1>
              <p>
                Subscribe to our newsletter to receive information about our{" "}
                <br /> products and other benefits.
              </p>
              <div className="email-box">
                <input
                  className="tbox"
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError("email")}
                  placeholder="Email address"
                />
                <button className="tbtn" type="submit" name="button">
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Newsletter;
