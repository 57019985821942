import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import Header from "./layout/Header";
import Home from "./pages/home";
import Shop from "./pages/shop";
import Contact from "./pages/contact";

import { Route, Routes, useNavigate } from "react-router-dom";
import About from "./pages/about";

import Cart from "./pages/cart";
import Login from "./auth/login";
import NotFound from "./pages/404";

import ProductDetails from "./pages/productDetails";
import Checkout from "./pages/checkout";

import { CartProvider } from "../src/cartContext";

// import Account from "./pages/account";

import SignIn from "./auth/signIn";
import TrackyourOrder from "./pages/trackyourOrder";

import TermCondition from "./pages/termCondition";
import Privacy from "./pages/privacy";
import ShopBakery from "./pages/shopBakery";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Account from "./pages/account";
import OrderHistory from "./pages/orderhistory";
import DashboardOrderHistory from "./dashboard/orderhistory";
import DashboardTestimonial from "./dashboard/testimonial";
import DashboardProductRating from "./dashboard/product-rating";
import DashboardAccount from "./dashboard/account";
import OrderCart from "./pages/orderCart";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import OrderDetail from "./pages/OrderDetail/OrderDetail";
import Search from "./pages/Search/Search";


function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loading">
          {/* Your loading animation or spinner */}
          <div className="spinner"></div>
        </div>
      ) : (
        <CartProvider>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route index element={<Home />}></Route>
              <Route
                path="productdetails/:slug"
                element={<ProductDetails />}
              ></Route>
              <Route path="shop/:slug/:id" element={<Shop />}></Route>
              <Route path="shop-bakery" element={<ShopBakery />}></Route>
              <Route path="about" element={<About />}></Route>

              <Route path="contact" element={<Contact />}></Route>

              <Route path="cart" element={<Cart />}></Route>

              <Route path="login" element={<Login />}></Route>
              <Route path="signin" element={<SignIn />}></Route>

              <Route path="privacy/policy" element={<Privacy />}></Route>

              <Route
                path="track-your-order"
                element={<TrackyourOrder />}
              ></Route>

              <Route
                path="terms-and-condition"
                element={<TermCondition />}
              ></Route>
              <Route path="/search" element={<Search />}></Route>
              <Route path="*" element={<NotFound />}></Route>
              <Route
                path="account"
                element={
                  <ProtectedRoute>
                    <Account />
                  </ProtectedRoute>
                }
              >
                {/* Nested routes for Profile and Order History */}
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <OrderHistory />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="order-history"
                  element={
                    <ProtectedRoute>
                      <OrderHistory />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="orderdetail/:id"
                  element={
                    <ProtectedRoute>
                      <OrderDetail />
                    </ProtectedRoute>
                  }
                />
                {/* <Route path="track-order" element={<TrackOrder />} /> */}
                <Route
                  path="order-cart"
                  element={
                    <ProtectedRoute>
                      <OrderCart />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>

            <Route
              path="dashboard-account"
              element={
                <ProtectedRoute>
                  <DashboardAccount />
                </ProtectedRoute>
              }
            >
              {/* Nested routes for Profile and Order History */}
              <Route
                index
                element={
                  <ProtectedRoute>
                    <OrderHistory />
                  </ProtectedRoute>
                }
              />

              <Route
                path="dashboard-order-history"
                element={
                  <ProtectedRoute>
                    <DashboardOrderHistory />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard-testimonial"
                element={
                  <ProtectedRoute>
                    <DashboardTestimonial />
                  </ProtectedRoute>
                }
              />
              <Route
                path="dashboard-product-rating"
                element={
                  <ProtectedRoute>
                    <DashboardProductRating />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            ></Route>
          </Routes>
        </CartProvider>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
