import { create } from "zustand";
import { persist } from "zustand/middleware";
const useStore = (set) => ({
  authUser: null,
  token: null,
  requestLoading: false,
  newToken: null,
  mainCate: 1,
  setAuthUser: (user) => set((state) => ({ ...state, authUser: user })),
  setToken: (token) => set((state) => ({ ...state, token: token })),
  setNewToken: (newToken) => set((state) => ({ ...state, newToken: newToken })),
  setMainCate: (mainCate) => set((state) => ({ ...state, mainCate: mainCate })),
  removeAuthUser: () =>
    set({
      authUser: null,
      token: null,
      newToken: null,
    }),
  setRequestLoading: (isloading) =>
    set((state) => ({ ...state, requestLoading: isloading })),
});

const useAuthStore = create(persist(useStore, { name: "auth" }));

export default useAuthStore;
