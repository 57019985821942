import { useState, useEffect } from "react";
import img1 from "../assets/img/other/testimonial-thumb1.png";
import img2 from "../assets/img/other/testimonial-thumb2.png";
import img3 from "../assets/img/other/testimonial-thumb3.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import toast from "react-hot-toast";
import { Axios } from "../config";
import requests from "../lib/requests";

const testimonials = [
  {
    name: "Ibukunoluwa",
    role: "Teacher",
    content:
      "I have been a loyal customer of Medmall Pharmacy for years, and I can't speak highly enough about their exceptional service and knowledgeable staff. Whether I need prescription medications or advice on over-the-counter remedies, they are always there to help with a smile.",
    image: img1,
  },
  {
    name: "Ibukunoluwa",
    role: "Teacher",
    content:
      "I have been a loyal customer of Medmall Pharmacy for years, and I can't speak highly enough about their exceptional service and knowledgeable staff. Whether I need prescription medications or advice on over-the-counter remedies, they are always there to help with a smile.",
    image: img2,
  },
  {
    name: "Ibukunoluwa",
    role: "Teacher",
    content:
      "I have been a loyal customer of Medmall Pharmacy for years, and I can't speak highly enough about their exceptional service and knowledgeable staff. Whether I need prescription medications or advice on over-the-counter remedies, they are always there to help with a smile.",
    image: img3,
  },
];
const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true, // Set to true for automatic sliding
    autoplaySpeed: 2000, // Set the interval (in milliseconds)
  };

  const [testimonyData, setTestimonyData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTestimony = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(requests.testimony);
      setTestimonyData(response.data);
      setLoading(false);
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch Testimony", {
        toastId: 1,
        duration: "300",
      });
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTestimony();
  }, []);

  return (
    <>
      <section className="testimonial__section testimonial__bg position__relative section--padding color-scheme-2">
        <div className="container">
          <div className=" text-center  mb-35">
            <h2 className="section__heading--maintitle style2">Testmonial</h2>
          </div>
          {loading ? (
            "Testimony Loading..."
          ) : (
            <div className="testimonial__bg--inner testimonial__activation--column1">
              {testimonyData.length === 0 ? (
                "No Testimony Data"
              ) : (
                <div className="swiper-wrapper">
                  <Slider {...settings}>
                    {testimonyData.map((testimonial, index) => (
                      <div className="swiper-slide" key={index}>
                        <div className="testimonial__items--style2 testimonial__items text-center">
                          <div className="testimonial__items--style2__thumbnail mb-10">
                            <img
                              className="testimonial__items--style2__thumbnail--img border-radius-50"
                              style={{
                                margin: "0px auto",
                                width: "140px",
                                height: "140px",
                                objectFit: "contain",
                              }}
                              src={`https://api.medmall.ng${testimonial.image}`}
                              alt={`testimonial-img-${testimonial.full_name}`}
                            />
                          </div>
                          <div className="testimonial__items--content">
                            <h2 className="testimonial__items--title text-black h3">
                              {testimonial.full_name}
                            </h2>
                            <span className="testimonial__items--subtitle text-black">
                              {testimonial.occupation}
                            </span>
                            <p className="testimonial__items--desc style2 text-black">
                              {testimonial.comment}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Testimonial;
