import { useLocation } from "react-router-dom";
import style from "./orderdetail.module.css";

const OrderDetail = () => {
  const location = useLocation();
  const data = location.state || {};

  const totalValue = data?.data?.reduce(
    (total, item) => total + item.price * item.qty,
    0
  );
  console.log(data);
  return (
    <div className="account__wrapper">
      <div className="account__content">
        <div className="" style={{ display: "flex" }}>
          <span className="account__content--title h3 mb-20">
            Orders Detail {data?.id}
          </span>
        </div>
        <table className={style.table}>
          <thead>
            <tr>
              <th style={{ width: "50%", textAlign: "center" }}>Image</th>
              <th style={{ width: "50%", textAlign: "center" }}>Product</th>
              <th style={{ width: "50%", textAlign: "center" }}>Total</th>
            </tr>
          </thead>
          <tbody>
            {data?.data?.map((item) => (
              <tr key={item.id}>
                <td style={{ width: "5%", textAlign: "center" }}>
                  <img src={`https://api.medmall.ng/${item.image}`} alt="" />
                </td>
                <td style={{ width: "50%", textAlign: "center" }}>
                  {item.name}
                </td>

                <td style={{ width: "50%", textAlign: "center" }}>
                  ₦{item.price * item.qty}
                </td>
              </tr>
            ))}
            <tr>
              <td>Subtotal</td>
              <td colSpan={2}>₦{totalValue}</td>
            </tr>
            <tr>
              <td>Payment method:</td>
              <td colSpan={2}>Flutterwave</td>
            </tr>
            <tr>
              <td>Note</td>
              <td colSpan={2}>{data?.data?.[0]?.description}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetail;
