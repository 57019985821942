import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useAuthStore from "../store/store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Axios } from "../config";
import requests from "../lib/requests";
import icon1 from "../assets/img/icon/clipboard-list.png";
import icon2 from "../assets/img/icon/order.png";
import { RiLogoutCircleLine } from "react-icons/ri";

const Account = () => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const { authUser, token, removeAuthUser, newToken } = useAuthStore();
  const handleLogout = async () => {
    try {
      const response = await Axios.post(
        requests.logout,
        { refresh: newToken || "" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      removeAuthUser();
      navigate("/");
      toast.success("Successfully logged out");
      return response.data;
    } catch (error) {
      removeAuthUser();
      navigate("/");
      toast.success("Successfully logged out");
    }
  };
  const handleModal = () => {
    setModal(!modal);
  };
  return (
    <>
      <main className="">
        <section className="">
          <div className="container-fluid">
            <div className="my__account--section__inner border-radius-10 d-flex pl-2">
              <div className="account__left--sidebar account ml-0">
                <h2 className="account__content--title h3 mb-20 pt-3">
                  My Profile
                </h2>
                <ul className="account__menu account">
                  <Link
                    to="/account/order-history"
                    className="account__menu--list active"
                  >
                    <span>
                      <img src={icon1} alt="" />
                    </span>
                    Orders
                  </Link>
                  <li className="account__menu--list" onClick={handleModal}>
                    <span>
                      <img src={icon2} alt="" />
                    </span>
                    Track order
                  </li>

                  <li className="account__menu--list logout">
                    <RiLogoutCircleLine color="#F93333" />
                    <span onClick={handleLogout}>Log Out</span>
                  </li>
                </ul>
              </div>

              <div className="account__content" style={{ flexGrow: "1" }}>
                <Outlet /> {/* Outlet to render nested routes */}
              </div>
            </div>
          </div>
        </section>
        {/* <!-- my account section end --> */}
      </main>
      {modal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            backgroundColor: "rgba(21, 14, 40, 0.93)",
            transition: "all easeInOut 500ms",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "999",
          }}
        >
          <div
            style={{
              border: "1px solid #2b1770",
              padding: "2rem",
              margin: "auto",
              borderRadius: "4px",
            }}
          >
            <p className="text-white">
              Thank you for your order, please check your email for order
              details. Thank you!
            </p>
            <button
              onClick={() => setModal(false)}
              style={{
                margin: "2rem auto 0",
                fontSize: "16px",
                backgroundColor: "#2b1770",
                borderRadius: "0.25rem",
                border: "none",
                color: "#fff",
                padding: "10px",
                width: "100%",
                display: "block",
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default Account;
