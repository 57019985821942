import React from "react";
import img from "../assets/img/icon/Vector.png";

import product from "../assets/img/icon/product.png";
import { FaStar } from "react-icons/fa6";
const DashboardProductRating = () => {
  return (
    <>
      <div className="account__wrapper">
        <div className="account__content">
          <span
            className="mb-20"
            style={{ color: "#3C3C3C", fontSize: "20px", fontWeight: "500" }}
          >
            Create Ratings
          </span>
          <div className="account__table">
            {/* <span class="mb-20" style={{}}>
              Set parameters
            </span> */}

            <div className="testimonial-container">
              <div>
                <div className="input-testimonial">
                  <span>Product Name</span> <br />
                  <input type="text" placeholder="Enter Product name" />
                </div>

                <div className="input-testimonial">
                  <span>Product Category </span> <br />
                  <input type="text" placeholder="Select Product Category" />
                </div>

                <div className="input-testimonial">
                  <span> Rate </span> <br />
                  <div className="star-container">
                    <FaStar color="#2563EB" />
                    <FaStar color="#2563EB" />
                    <FaStar color="#2563EB" />
                    <FaStar color="#D1D5DB" />
                    <FaStar color="#D1D5DB" />
                  </div>
                </div>
              </div>

              <div className="" style={{ marginLeft: "50px" }}>
                <img src={img} alt="" />
              </div>

              <div className="testimonial-col2">
                <span className="">Preview</span>

                <div className="product-container">
                  <div>
                    <img src={product} alt="" />
                  </div>
                  <div className="product-content">
                    <h6>jacket women</h6>
                    <h6 className="" style={{ fontWeight: "600" }}>
                      Insert product title
                    </h6>
                    <div className="">
                      <span className="current__price">110</span>-
                      <span className="old__price">70</span>
                    </div>
                    <div className="" style={{ display: "flex", gap: "10px" }}>
                      <FaStar color="red" />
                      <FaStar color="red" />
                      <FaStar color="red" />
                      <FaStar color="red" />
                      <FaStar color="red" />
                    </div>
                  </div>
                </div>

                <div className="btn-container">
                  <button className="publish">Publish</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardProductRating;
